import { Link, graphql } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import * as Contentstyle from '../../components/content/content.module.scss'
import * as style from '../../components/album/album.module.scss'
import Content from '../../components/content/content'
import Img from "gatsby-image"
import React from 'react'

const SlightDetourEP = ( {data} ) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div className={style.albumDetails}>
        {modal ? (
          <Link to={closeTo}>
            <svg className={style.albumDetails__close} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.53553 0.464768C7.92606 0.855293 7.92606 1.48846 7.53553 1.87898L5.41421 4.0003L7.53553 6.12162C7.92606 6.51215 7.92606 7.14531 7.53553 7.53584C7.14501 7.92636 6.51184 7.92636 6.12132 7.53584L4 5.41452L1.87868 7.53584C1.48815 7.92636 0.854988 7.92636 0.464463 7.53584C0.0739387 7.14531 0.0739387 6.51215 0.464463 6.12162L2.58578 4.0003L0.464463 1.87898C0.073939 1.48846 0.0739387 0.855293 0.464463 0.464768C0.854988 0.0742437 1.48815 0.0742441 1.87868 0.464768L4 2.58609L6.12132 0.464768C6.51184 0.0742441 7.14501 0.0742437 7.53553 0.464768Z" />
            </svg>
          </Link>
        ) : (
          <div>
            {/* Not sure */}
          </div>
        )}

          <Content extraClass='content--modal'>
            <div className="column column--40">
              <div className="sticky">

            <Img className={style.albumDetails__cover} fluid={data.cover.childImageSharp.fluid} />

            <p className={style.albumDetails__credits}>
              <strong>Executive Producer:</strong> Specyal T<br/>
              <strong>Label:</strong> Independent<br/>
              <strong>Recorded At:</strong> Avenue Kode Studios<br/>
              <strong>Production Provided By:</strong> Mixtape Seoul<br/>
              <strong>Mixed & Mastered By:</strong> Jonathan Paris<br/>
            </p>
            
            <div className={Contentstyle.subtitleLowercase}>Tracks</div>

            <ol className={style.albumDetails__tracks}>
              <li>Midnight Shade</li>
              <li>Holiday Linen</li>
              <li>Coffee Clicked</li>
              <li>Virtual Bae</li>
              <li>Pink Lemonade</li>
            </ol>
              </div>

            </div>
            <div className="column column--60">
            <span className={Contentstyle.titleLowercase}>Track Synopsis</span>

              <p><strong>Midnight Shade</strong><br/>
              Out with some guy friends at a club, the ladies seem more interested in the female persuasion.</p>

              <hr />
              <p><strong>Holiday Linen</strong><br/>
              Two co-workers at a hotel resort fall in love and have a surprise secret affair.</p>

              <hr />
              <p><strong>Coffee Clicked</strong><br/>
              Two cousins meet for the first time by happenstance at their local coffee shop after their coffee orders get mixed up.</p>

              <hr />
              <p><strong>Virtual Bae</strong><br/>
              During a time of quarantine, a deep relationship develops online over months.</p>

              <hr />
              <p><strong>Pink Lemonade</strong><br/>
              After kissing a new love interest for the first time, sexy-sweet memories of an ex come flooding back.</p>

           </div>

          </Content>

      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default SlightDetourEP


export const query = graphql`
query {
  cover: file(relativePath: {eq: "st-store/albums/slight-detour-ep.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
}
`